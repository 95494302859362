<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Крипто активы</h5>
    <DataTable
      :value="tokens"
      dataKey="id"
      v-model:wallets="tokens"
      responsiveLayout="scroll"
    >
      <Column header='id' field='id'/>
      <Column header='Название' field='name'/>
      <Column header='Обозначение' field='mnemonic'/>
      <Column header='Адрес' field='createdAddress'/>
      <Column class="bg-white border-none flex flex-nowrap justify-content-end">
        <template #body="slot">
          <div data-help='MetaMask'>
            <div
            @click="metamaskAddEthereumCoin(slot.data)"
            class="metamask m-1"></div>
          </div>
          <div data-help='Copy'>
            <Button
              icon="pi pi-copy ert"
              class="p-button-rounded p-button-warning m-1"
              @click="copy(slot.data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import MetaMask from '@/mixins/MetaMask'

export default {
  name: 'Tokens',
  data(){
    return {
      tokens: []
    }
  },
  mixins: [MetaMask],
  mounted() {
    this.getTokens();
  },
  methods:{
    async getTokens(){
      try {
        const resp = await this.axios.get("api/au_tokens");
        this.tokens = resp.data;
      }
      catch (e) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in au_tokens api call',
          group: 'info',
          life: 3000,
        })
      }
    },
    copy(data){
      navigator.clipboard.writeText(data.createdAddress);
    },
  }
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: 7px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
.metamask {
  width: 3rem;
  height: 3rem;
  background: url(../../assets/img/metamask.png);
  background-size: cover;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid rgb(245, 159, 10, 0.4);
  padding: 20px;
  cursor: pointer;
  box-shadow: 9px 9px 14px -8px rgba(245, 159, 10, 0.2) inset;
  transition: 0.5s
}
.metamask:hover {
  border: 1px solid rgb(245, 159, 10, 0.8);
  box-shadow: 9px 9px 14px -8px rgba(245, 159, 10, 0.6) inset;
  transition: 0.5s
}
</style>